<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'

export default {
  name: 'dictionaryDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        type: 'list'
      }
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail.call(this, {
        url: `/dict/${id}`
      })
  },
  methods: {
    getForm1() {
      return {
        title: '字典信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '字典名称',
            type: 'input',
            cols: 12,
            key: 'name',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '字典类型',
            type: 'input',
            cols: 12,
            key: 'type',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '备注',
            type: 'input',
            cols: 12,
            key: 'remark'
          }
        ]
      }
    },

    getForm2() {
      return {
        title: '字典设置',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '字典编码',
            type: 'input',
            cols: 12,
            key: 'code',
            props: {
              placeholder: '数字,字母,下划线组成,不能包含其他符号'
            },
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '系统内置',
            type: 'select',
            cols: 12,
            key: 'sys',
            defaultValue: '0',
            typeData: [
              {
                name: '是',
                value: '1'
              },
              {
                name: '否',
                value: '0'
              }
            ]
          },
          {
            name: '字典描述',
            type: 'input',
            cols: 24,
            key: 'description'
          }
        ]
      }
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            console.log(data)
            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: '/dict',
                params: {
                  ...data
                },
                isPost: false
              })
              .then(result => {
                this.$router.push('/system/dictionary')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/system/dictionary')
          }
        }
      ]

      return {
        left
      }
    }
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} form={this.detail} data={[this.getForm1(), this.getForm2()]} />
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
